<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Ganhadores</h1>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text class="modal-cadastro-form pa-3 pb-0">
              <v-row class="align-end">
                <v-col class="pa-3 pt-1 mt-0 pb-0" cols="12" :md="6" sm="12">
                  <rangeDate class="pb-0 mb-0"
                    :dtFinalProps.sync="dtFinal"
                    :dtInicialProps.sync="dtInicial"
                    :hasMonthOrDateProps.sync="hasMonthOrDate"
                    :inativosProps.sync="inativos"
                  />
                </v-col>

                <v-col class="pt-3 pa-3 pb-0" v-if="!isAdmin" cols="12" md="4" sm="12">
                  <v-text-field
                    :class="'bug-input'"
                    :label="$gettext('Código do Bilhete')"
                    v-model="codBilhete"
                    :error-messages="codBilheteErrors"
                    dense
                    @input="$v.codBilhete.$touch()" 
                    @blur="$v.codBilhete.$touch()" 
                  />
                </v-col>
                
                <v-col class="pa-3 pt-0 pb-1" v-if="isAdmin" cols="12" md="2" sm="12">
                  <v-autocomplete
                    v-model="loteria"
                    :items="loterias"
                    dense
                    label="Loterias"
                  />
                </v-col>

                <v-col class="pa-3 pt-0 pb-1" v-if="isAdmin" cols="12" md="2" sm="12">
                  <autoCompleteEntities :entityProps.sync="entity" :typesEntities="['4']"/>
                </v-col>

                <v-col class="pa-3 pt-0 pb-0" cols="12" md="2" sm="12">
                  <vmoney label="Valor" v-model="value" :options="{ locale: 'pt-BR', prefix: 'R$ ', suffix: '', length: 11, precision: 2 }"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col  v-if="isAdmin" dense cols="12" md="6" sm="6" class="position-buttons">
                  <v-btn
                    :color="baixados === false ? variables.colorWrapper : variables.colorSuccess"
                    :class="[baixados === false ? 'button-premio': 'button-premio-alto']"
                    @click="requestBaixados()"
                    > Baixados </v-btn>
                  <v-btn
                    v-if="permiteAcao({ path: '/consultas/ganhadores/premios-altos' })"
                    :color="premioAlto === true ? variables.colorSuccess : variables.colorWrapper"
                    :class="['ml-2',premioAlto === true ? 'button-premio-alto' : 'button-premio']"
                    @click="requestPremiosAltos()"
                    > Prêmios Altos </v-btn>

                  <v-btn
                    v-if="permiteAcao($route)"
                    :color="premiosWeb === false ? variables.colorWrapper : variables.colorSuccess"
                    :class="['ml-2',premiosWeb === false ? 'button-premio': 'button-premio-alto']"
                    @click="requestPremiosWeb()"
                    > Web </v-btn>

                  <v-btn
                    v-if="permiteAcao($route)"
                    :color="prescrito === false ? variables.colorWrapper : variables.colorSuccess"
                    :class="['ml-2',prescrito === false ? 'button-premio': 'button-premio-alto']"
                    @click="requestPremiosPrescrito()"
                  > 
                    Prescrito
                  </v-btn>
                </v-col>


                <v-col :class="['text-right', isAdmin ? 'col-md-6': 'col-12 col-md-12']"  >
                  <v-btn
                    :color="variables.colorPrimary"
                    @click.prevent="openModalFiltro"
                    class="br-btn mais-filtros mr-2"
                    :class="{'isActive': dadaMaisFiltro }"
                    :loading="loading">
                      + filtros
                  </v-btn>
                
                  <v-btn
                    type="submit"
                    :color="variables.colorPrimary"
                    @click.prevent="submit"
                    class="br-btn mr-2"
                    :loading="loading">
                      Buscar
                
                  </v-btn>
                
                  <v-btn :color=" variables.colorWrapper" @click="limpar" class="button-premio"> Limpar Filtros </v-btn>
                
                  <v-btn
                    :color="variables.colorSecondary"
                    @click.prevent="baixarSelecionados"
                    v-if="itensSelect.length > 0"
                    :loading="loadingBaixarSelecionados"
                    dark>Baixar selecionado
                    </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="pt-0">
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div class="box-table box-table-ganhadores">
         <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            v-model="itensSelect"
            :headers="headers"
            :items="listaItens"
            show-expand
            :expanded.sync="expanded"
            :single-select="true"
            show-select
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"
            @item-expanded="itemExpandRequest"
          >

            <template v-slot:item.entity.cod="{ item }">
              {{ getEntityOrigin(item) }} {{ getEntityPayament(item) }}
            </template>
            
            <template v-slot:item.winners.games.gameNumber="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ item.winners ? item.winners.games.gameNumber : formatGeral(item, 'gameId') }}</span>
                </template>
                <span>{{ item.id }}</span>
              </v-tooltip>

              
            </template>
            <template v-slot:item.winners.sweepstake.cod="{ item }">
              <TooltipResultados 
                v-if="item.winners && item.winners.sweepstake.cod === 'INSTANTÂNEA'"
                :item="item.winners.games"
                :loading="loadingResultados"
                :active="ativeTooltipResultados"
                :texto="item.winners.sweepstake.cod"
                @getResultados="getResultados"
              />

              <template v-else>
                {{ item.winners ? item.winners.sweepstake.cod : formatGeral(item, 'labelLottery') }}
              </template>
            </template>

            <template v-slot:item.winners.totais.guessValue="{ item }">
              {{ item.winners ? item.winners.totais.guessValue : vlGamesValue(item.params) | currency }}
            </template>

            <template v-slot:item.value="{ item }">
              {{ item.value | currency }}
            </template>

            <template v-slot:item.totais.prizeValue="{ item }">
              {{ item.totais.prizeValue | currency }}
            </template>

            <template v-slot:item.winners.sweepstake.dtDraw="{ item }">
              {{ item.winners ? item.winners.sweepstake.dtDraw : item.created | datas }}
            </template>

            <template v-slot:item.winners.games.created="{ item }">
              {{ item.winners ? item.winners.games.created : item.created | datas }}
            </template>

            <template v-slot:item.lowUser.name="{ item }">
              {{ item.lowUser ? item.lowUser.name : 'sistema' }}
            </template>

            <template v-slot:item.updated="{ item }">
              {{ item.updated | datas }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="item.status === 'N' && verificaPermicao(premiosWeb) && !loading"
                small
                @click="handlerBaixarPremios(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Baixar
              </v-btn>
              <v-btn
                v-if="item.status === 'N'  && permiteAcao({path: '/consultas/resgate-premios'}, 'edit') && !loading"
                small
                @click="handlerPrescrever(item)"
                tile
                outlined 
                :color="variables.colorSecondary"
                class="mr-2"
              >
                Prescrever
              </v-btn>

              <!-- Premio já Baixado -->
              <v-chip v-if="item.status === 'B'" :color="variables.colorSecondary" dark> Baixado </v-chip>

            </template>


            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div class="box-table-palpites">
                  <v-skeleton-loader
                    :loading="loadingSubTable"
                    :transition="'fade-transition'"
                    type="table"
                    height="500">

                  <div v-if="item.new">
                      <div :key="`label-${k}`" class="mt-2">
                      </div>
                      <v-data-table
                        class="mt-2"
                        :headers="headersPrizeWeb"
                        :items="item.new"
                        hide-default-footer
                        :key="`web-${k}`"
                        dense
                        :items-per-page="item.new.length"
                      >
                      
                        <template v-slot:item.guesses="{item}">
                            {{ item.guesses }}
                        </template>
                        
                        <template v-slot:item.guessValue="{item}">
                            {{ item.guessValue | currency }}
                        </template>

                        <template v-slot:item.prizeValue="{item}">
                            {{ item.prizeValue | currency }}
                        </template>
                    </v-data-table>
                  </div>
                  
                  <!-- se for premio sem agrupamento de loterias -->
                  <v-data-table v-else
                    :headers="headersSubTable"
                    :items="item.winners ? item.winners.games.guessWinners : item.new"
                    hide-default-footer
                    dense
                    item-key="bb"
                    :key="'asdsa'"
                    :items-per-page="item.winners ? item.winners.games.guessWinners.length : item.new ? item.new.length : 0 "
                  >
                  
                  <template v-slot:item.prizeValue="{item}">
                    {{ item.prizeValue | currency }}
                  </template>
                  
                  <template v-slot:item.guessValue="{item}">
                    {{ item.guessValue | currency }}
                  </template>
                    
                  </v-data-table>

                  </v-skeleton-loader>
                </div>
              </td>
            </template>

            <template slot="body.append">
              <tr style="background: #4CAF50; color:white;">
                  <th colspan="3" style="font-weight: 400 !important">Nº Bilhetes: {{ totalGeral.bilhetes ? totalGeral.bilhetes : '0' }} </th>
                  <th style="font-weight: 400 !important">Valor jogos: {{ totalGeral.jogos? totalGeral.jogos : '0,00' | currency }} </th>
                  <th style="font-weight: 400 !important">Prêmios Pagos: {{ totalGeral.pagos ? totalGeral.pagos : '0,00' | currency }} </th>
                  <th style="font-weight: 400 !important">Prêmios Pendentes: {{ totalGeral.pendentes ? totalGeral.pendentes : '0,00' | currency }} </th>
                  <th style="font-weight: 400 !important">Prêmios Prescrito: {{ totalGeral.prescrito ? totalGeral.prescrito : '0,00' | currency }} </th>
                  <th style="font-weight: 400 !important">Total Geral: {{ totalGeral.totais === 0 ? '0,00' : totalGeral.totais | currency }} </th>

                  <th></th>
                  <th></th>
                  <th></th>
              </tr>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
      <modalBaixar />
      <modalMaisFiltros 
        :loterias="loterias"
        :loadingBtnBusca="loadingBtnModalSalvar"
        @buscarGanhadores="buscarGanhadores"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'
import { currency } from '@/core/service/utils'
import { map, reduce, findIndex } from 'lodash'
import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'ganhadores'

export default {
  name: 'ConsultasGanhadores',
  components: {
    autoCompleteEntities: () => import('../../components/autoCompleteEntities'),
    vmoney: () => import('@/views/components/vMoney'),
    rangeDate: () => import('../../components/rangeDate'),
    modalBaixar: () => import('./components/modalBaixar'),
    modalMaisFiltros: () => import('./components/modalMaisFiltros'),
    TooltipResultados: () => import('./components/tooltipResultados'),
  },
  mixins: [listagemTables, validationMixin],
  data: () => ({
    premioAlto: true,
    premiosWeb: false,
    prescrito: false,
    baixados: false,
    listWinnersGambis: [],
    entity: {},
    expanded:[],
    headers: [
      { align: 'start', class: 'table-header', text: 'Revenda / Pagamento', value: 'entity.cod' },
      { align: 'start', class: 'table-header', text: 'N° Jogo', value: 'winners.games.gameNumber' },
      { align: 'start', class: 'table-header', text: 'Loteria', value: 'winners.sweepstake.cod' },
      { align: 'start', class: 'table-header', text: 'V.Jogo(R$)', value: 'winners.totais.guessValue' },
      { align: 'start', class: 'table-header', text: 'V.Prêmio(R$)', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Data Sorteio', value: 'winners.sweepstake.dtDraw' },
      { align: 'start', class: 'table-header', text: 'Transmissão', value: 'winners.games.created' },
      { align: 'start', class: 'table-header', text: 'Atualizado', value: 'updated' },
      { align: 'start', class: 'table-header', text: 'Usuário', value: 'lowUser.name' },
      { align: 'end', class: 'table-header', text: '', value: 'actions' },
    ],

    headersSubTable: [
      { align: 'start', class: 'table-header', text: 'Modalidade', value: 'modality.name' },
      { align: 'start', class: 'table-header', text: 'Palpite', value: 'guess.guess' },
      { align: 'start', class: 'table-header', text: 'Prêmio', value: 'prizeRange' },
      { align: 'start', class: 'table-header', text: 'Valor Jogo(R$)', value: 'guessValue' },
      { align: 'start', class: 'table-header', text: 'Valor Prêmio (R$)', value: 'prizeValue' },
    ],
    headersPrizeWeb: [
      { align: 'start', class: 'table-header', text: 'Loteria', value: 'lottery' },
      { align: 'start', class: 'table-header', text: 'Modalidade', value: 'modality' },
      { align: 'start', class: 'table-header', text: 'Palpite', value: 'guesses' },
      { align: 'start', class: 'table-header', text: 'Prêmio', value: 'prizeRange' },
      { align: 'start', class: 'table-header', text: 'Valor Jogo(R$)', value: 'guessValue' },
      { align: 'start', class: 'table-header', text: 'Valor Prêmio (R$)', value: 'prizeValue' },
    ],
    value: 0,
    loading: false,
    loadingTable: false,
    loadingSubTable: false,
    dtFinal:  '',
    dtInicial: '',
    inativos: null,
    hasMonthOrDate: null,
    loterias: [],
    diff: 1,
    loteria: '',
    itensSelect: [],
    codBilhete: '',
    page: 1,
    dadaMaisFiltro: '',
    loadingBtnModalSalvar: false,
    loadingResultados: false,
    ativeTooltipResultados: false,
  }),

  watch: {
    hasMonthOrDate (val) {
      this.setHasMonthOrDate(val)
    },

    loteria () {
      this.page = 1
      this.clearPage()
    }    
  },

  mounted () {
    const vm = this
    Events.$on('filter::clear', () => {
      this.loteria = this.loterias[0]
      this.entity = {}
      this.value = 0
      this.baixados = false
      this.premioAlto = false
      this.premiosWeb = false
    })

    Events.$on('update::view::baixa', () => { vm.submit() })

    this.getSweepstakesRecurrent({ order: { id: 'desc' } })
      .then(result => {
        this.loterias = result.data.resultMap.map(item => ({
          text: `${item.id} - ${item.cod}`,
          value: item.id
        }))

        this.loterias.unshift({
          text: 'Todos',
          value: ''
        })
      })
  },

  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens']),
    ...mapGetters('auth', ['user']),

    isAdmin () {
      // return this.user.userEntityPaths.length > 0 ? false : true
      return true
    },

    codBilheteErrors () {
      const errors = []
      if (!this.$v.codBilhete.$dirty) return errors
      !this.$v.codBilhete.required && errors.push('Código do bilhete é obrigatório')
      return errors
    },

    totalGeral () {
      let jogos = this.listaItens[0]
      if(!jogos || jogos === undefined) return { pagos: 0, pendentes: 0}

      let premios = {
        pagos: parseFloat(jogos.allValues[0].downloaded).toFixed(2),
        pendentes: parseFloat(jogos.allValues[0].pending).toFixed(2),
        prescrito: parseFloat(jogos.allValues[0].expired).toFixed(2),
        jogos: parseFloat(jogos.allValues[0].games),
        bilhetes: parseFloat(jogos.allValues[0].tickets).toFixed(2)
      }

      premios.totais = +premios.pagos + (+premios.pendentes)
      return premios
    }
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'setSubTable', 'getPremioAltos', 'clearItens', 'filterWinners', 'getResultsInst']),
    ...mapActions('resgate', {
      baixarPremios: 'editarItem',
      prescreverPremio: 'prescreverPremio',
    }),

    ...mapActions('sweepstakesRecurrent', {
      getSweepstakesRecurrent: 'getItens'
    }),

    init() {},
    verificaPermicao () { // verifica permicao de ação para premios web e prognosticos
      // if (this.premiosWeb) {
      //   console.log('veio aqui', this.permiteAcao({ path: '/consultas/resgate-premios/web/edit'}, 'edit'))
      //   return this.permiteAcao({ path: '/consultas/ganhadores/premio/web'}, 'edit')
      // }
      return this.permiteAcao({path: '/consultas/resgate-premios'}, 'edit')
    },
    openModalBaixar (item) {
      Events.$emit('ganhadores::modal::baixar', item)
    },
    itemExpandRequest (v) {
      if (v.winners === undefined) {
        this.loadingSubTable = false
        return false
      }
      this.expanded = v.item === this.expanded[0] ? [] : [v.item]
      
      if (!v.value) return false
      this.listWinnersGambis = []
      let index = findIndex(this.listaItens, obj => obj.id === v.item.id)
      if (v.item.pendingOperationTypeId === 'SRP') {
        this.loadingSubTable = true
          let format = map(v.item.params, val => {
            return {
              modality:{ name: val},
              guess:{guess: val},
              prizeRange: val,
              guessValue: val.vlGame,
              prizeValue: val,
              games:{gameNumber: 1, guessWinners: [{}]},
              totais: {
                guessValue: 1
              }
            }
          })
        
        this.setSubTable({ format, id: v.item.id, key: index, lista: v.item.params.list }).then(() => {
          this.loadingSubTable = false
        })
      } else {
        return
      }
    },
    limpar () { 
      Events.$emit('filter::clear') 
      this.dadaMaisFiltro = ''
    },
    paginacao (pag) {
      this.loadingPag = true
      this.disabled = true
      this.setPage(pag)
      this.page = pag
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      if (this.dadaMaisFiltro) {
        this.filterWinners({ page: this.page, pageSize: this.$store.getters.ItensPaginacao, ...this.dadaMaisFiltro })
          .finally(() => {
            this.loadingPag = false
            this.disabled = false
          })
      } else {
        this.submit()
      }

    },


    submit () {
      
      if (this.$v.codBilhete.$invalid) { 
        this.$v.codBilhete.$touch()
        return false 
      }

      if (this.baixados && !this.premioAlto) {
        console.log('premio baixo e baixado')

        this.$swal({
          icon: 'warning',
          text: `Não é possível buscar por prêmios baixos e baixados`,
          showCancelButton: false,
          showConfirmButton: false,
        })

        return 
      }
      
      this.loading = true
      this.loadingPag = true
      this.disabled = true
      this.expanded = []

      let { dtInicial, dtFinal, loteria, codBilhete, entity, baixados, premiosWeb, prescrito } = this
      
      if (this.premioAlto === false) {
        const data = { page: this.page, pageSize: this.$store.getters.ItensPaginacao, dtInicial, dtFinal, loteria, premio: this.premioAlto, codBilhete, entity:entity.value, baixados, premiosWeb, prescrito }
        if (this.value > 0) data.value = this.value

        this.getItens(data).then(() => {
          this.loading = false
          this.loadingPag = false
          this.disabled = false
        }).catch(err => {
          this.loading = false
          this.loadingPag = false
          this.disabled = false
          console.log(err)
        })
      } else {
        const data = { page: this.page, pageSize: this.$store.getters.ItensPaginacao, dtInicial, dtFinal, loteria, premio: this.premioAlto, codBilhete, entity:entity.value, baixados, premiosWeb, prescrito }
        if (this.value > 0) data.value = this.value

        this.getPremioAltos(data).then(() => {
          this.loading = false
          this.loadingPag = false
          this.disabled = false
        }).catch(err => {
          this.loading = false
          this.loadingPag = false
          this.disabled = false
          console.log(err)
        })
      }
    },
    requestPremiosAltos () {
      this.premioAlto = !this.premioAlto
      this.submit()
    },
    requestPremiosWeb () {
      this.premiosWeb = !this.premiosWeb
      this.baixados = false
      this.submit()
    },
    requestBaixados () {
      this.baixados = !this.baixados
      this.submit()
    },

    requestPremiosPrescrito () {
      this.prescrito = !this.prescrito
      this.baixados = false
      this.premiosWeb  = false
      this.submit()
    },

    handlerBaixarPremios (item) {
      this.itensSelect = []
      this.itensSelect.push(item)
      this.baixarSelecionados()
    },

    handlerPrescrever (item) {
      console.log(item)
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja prescrever esse prêmio no valor de ${currency(item.value)}`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.prescreverPremio({ id: item.id })
          .then(() => {
              let { dtInicial, dtFinal, loteria } = this
              this.loadingPag = true
              this.getItens({ dtInicial, dtFinal, loteria }).then(() => {
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              const msg = {
                toggle: true,
                type: 'error',
                msg: err.error
              }
              Events.$emit('snackbarCadastro::msg', msg)
            })
          })
        }
      })
    },

    baixarSelecionados () {
      const ids = []
      let total = 0
      this.itensSelect.forEach(item => {
        total += parseFloat(item.value)
        ids.push(item.id)
      })



      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja baixar esse prêmio no valor de ${currency(total)}`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.baixarPremios({ ids}).then(() => {
            this.loadingPag = true
            let { dtInicial, dtFinal, loteria } = this
            this.getItens({ dtInicial, dtFinal, loteria }).then(() => {
              this.loading = false
            }).catch(err => {
              this.loading = false
              console.log(err)
            })
          }).catch(err => {
            const msg = {
              toggle: true,
              type: 'error',
              msg: err.error
            }
            Events.$emit('snackbarCadastro::msg', msg)
          })
          .finally(() => {
            this.loadingPag = false
          })
        }
      })
      
      

      // this.baixarPremios({ ids })
    },
    vlGamesValue (obj) {
      if(!obj) return 0
      delete obj.token
      return reduce(obj, (a, b) =>  parseFloat(a) + parseFloat(b.vlGame), 0)
    },
    formatGeral (obj, key) {
      console.log('obj', obj)
      if(obj.entryId === null && obj.winners === null) return '-'
      delete obj.token
      return map(obj.list, v => v[key]).join(' - ')
    },

    getEntityOrigin (item) {
      if (item.winners && item.winners.games)
        return item.winners.games.entity.cod
    },

    getEntityPayament (item) {
      if (item.winners && item.winners.games){
        if (item.winners.games.entity.cod !== item.entity.cod)  {
          return `/ ${item.entity.cod}`
        }
      }

      return 
    },

    openModalFiltro () {
      Events.$emit('ganhadores::modal::maisFiltros::open')
    },

    buscarGanhadores (data) {
      this.dadaMaisFiltro = data
      this.dadaMaisFiltro.paymentDate = this.dadaMaisFiltro.paymentDate ? this.dadaMaisFiltro.paymentDate : undefined
      this.dadaMaisFiltro.idEntityRevenda = this.dadaMaisFiltro.entity ? this.dadaMaisFiltro.entity.value : undefined
      this.dadaMaisFiltro.largePrize = this.dadaMaisFiltro.largePrize ? this.dadaMaisFiltro.largePrize : undefined
      this.dadaMaisFiltro.premiosWeb = this.dadaMaisFiltro.premiosWeb ? this.dadaMaisFiltro.premiosWeb : undefined

      this.loadingBtnModalSalvar = true
      this.filterWinners({ page: this.page, pageSize: this.$store.getters.ItensPaginacao, ...data })
      .then(() => Events.$emit('ganhadores::modal::maisFiltros::close'))
      .finally(() => this.loadingBtnModalSalvar = false)
    },

    getResultados (gameId) {
      this.loadingResultados = true
      this.ativeTooltipResultados = true
      this.getResultsInst({ gameId }).finally(() => this.loadingResultados = false)
    }
  },

  validations () {
    return  {
      codBilhete: {
        required: requiredIf(function () {
          return !this.isAdmin
        })
      },
    }
  }

}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .bug-input{
    position: relative;
    top: -4px;
  }
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }
 .v-btn--contained.button-premio {
    font-size: 14px;
    border-radius: 0 !important;
    color: $colorPrimary !important;
    border: 1px solid $colorPrimary !important;
    box-shadow: none !important;
    font-weight: normal !important;
    &-alto {
      border-radius: 0 !important;
      font-size: 14px;
      font-weight: normal !important;
      box-shadow: transparent !important;
      color: white !important;
    }
  }
  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    &.box-table-ganhadores {
     .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        // padding: 15px 0;
      } 

      .v-data-table tbody tr.v-data-table__expanded__content {
        box-shadow: none;
      }
    }

    .box-table-palpites {
      .v-data-table {
        margin: 20px 0
      }
    }
    
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
  .position-buttons {
    @media(max-width: 600px) {
      text-align: center;
    }
  }

  .mais-filtros.isActive {
    background-color: $colorGreenDarken !important;
  }
</style>>
